import {OrbitControls} from '@react-three/drei'
import {forwardRef} from 'react'

const Controls = forwardRef((props, ref) => {
	return (
		<OrbitControls
			makeDefault // Make default so the camera starts a bit zoomed out
			position={[0, 5, 0]}
			rotateSpeed={0.5}
			minAzimuthAngle={0}
			maxAzimuthAngle={Math.PI / 1.7}
			minPolarAngle={0}
			maxPolarAngle={Math.PI / 2}
			enableDamping
			dampingFactor={0.02}
			zoomSpeed={2}
			minDistance={2} // Adjust the minDistance to prevent zooming too close
			maxDistance={10}
			enablePan={false} // DISABLE FOR RIGHT MOUSE MOVEMENT
			ref={ref}
		/>
	)
})

export default Controls